.container {
    position: relative;
}

.captchaBackground {
    cursor: pointer;
}

.captchaImage {
    position: absolute;
    left: 0;
}