.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error {
    color: red;
}
